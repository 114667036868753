import Photo1 from "./assets/2v_kuva.jpeg";
import Photo2 from "./assets/2v_kuva_2.jpeg";
import Photo3 from "./assets/2v_kuva_3.jpeg";
import Photo4 from "./assets/2v_kuva_4.jpeg";
import { createRef, useEffect, useState } from "react";

function App() {
  const imageRef = createRef();
  const [height, setHeight] = useState(250);
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    if (window.location.search.includes("cjvminmczuubzexnpiagciewkihlkarz")) {
      setAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (imageRef && imageRef.current && imageRef.current.clientWidth) {
      setHeight(imageRef.current.clientWidth);
    }
  }, [imageRef]);

  const images = [Photo1, Photo2, Photo3, Photo4];

  if (authenticated) {
    return (
      <div>
        <div className={"paper animate__animated animate__tada"}>
          <h1>Tervetuloa viettämään Olgan syntymäpäiviä 02.09.2023</h1>
          <p>
            Juhlat ovat tutussa paikassa Hostel Riverissä (Karjapiha 2, 28100,
            Pori). Ovet aukeavat kello 15.00 ja juhlat kestänevät sen pari
            tuntia maksimissaan.
          </p>
          <p>Lahjaksi Olga toivoo Universal audion äänikorttia tai Neumannin U87 mikrofonia.</p>
          <p>Olgan vaatekoko on 92 ja hän rakastaa Pipsa-Possua, sekä vauvajuttuja. Pääasia, että tulet paikalle!</p>
          <p>Olette lämpimästi tervetulleita viettämään tyttäremme juhlaa!</p>
          {images.map((image) => {
            return (
              <div
                key={image}
                ref={imageRef}
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  width: "calc(50% - 16px)",
                  height: height,
                  transition: ".24s",
                  background: `url(${image})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  margin: 8,
                  borderRadius: 16,
                }}
              ></div>
            );
          })}
          <h2>Tiedot:</h2>
          <p>Juhlapaikan osoite: Karjapiha 2, 28100 Pori, Hostel River</p>
          <p>Veeran puhelinnumero: 044 3007 115</p>
          <p>Ollin puhelinnumero: 044 980 1920</p>
        </div>
      </div>
    );
  } else {
    return <div className={"paper"}>Hmm...</div>;
  }
}

export default App;
